import MDinput from '@/components/MDinput';
import { validatorEmail, validatorRequire, validatorRequireNumber } from '@/utils/validators';
import Select from '@/components/DSE/Select';
import CrudBulkOrder from '../components/CrudBulkOrder';
import Switch from '../../../components/DSE/Switch';
import TextArea from '../../../components/DSE/TextArea';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, true], // Page, tab 1, tab 2, ..
    showFormLabels: [true, true], // Tab 1, tab 2, ....
    labels: ['common.customer', 'common.bulkOrders'],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      // tab 1
      {
        name: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        snelStartKlantCode: {
          type: MDinput,
          props: {
            type: 'number',
            precision: 0,
            required: true,
            max: maxNumber
          }
        },
        firstName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        lastName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        userName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        email: {
          type: MDinput,
          defaultValue: '',
          props: {
            type: 'email',
            maxlength: 64
          },
          rules: [{ validator: validatorEmail }]
        },
        customerSpecificCategoryName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64
          }
        },
        publishToWebshopYN: {
          type: Switch,
          defaultValue: false,
          props: {
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        billingAddressID: {
          type: Select,
          props: {
            caption: 'common.billingAddress',
            valueField: 'id',
            labelField: 'companyName',
            loading: vue.$store.getters['customerAddress/isLoading'],
            options: vue.$store.getters['customerAddress/loadedItems'],
            required: true
          },
          rules: [{ validator: validatorRequireNumber }]
        },
        shippingAddressID: {
          type: Select,
          props: {
            caption: 'common.shippingAddress',
            valueField: 'id',
            labelField: 'companyName',
            loading: vue.$store.getters['customerAddress/isLoading'],
            options: vue.$store.getters['customerAddress/loadedItems'],
            required: true
          },
          rules: [{ validator: validatorRequireNumber }]
        },
        syncError: {
          type: TextArea,
          defaultValue: '',
          props: {
            disabled: true
          }
        }
      },
      // tab 2
      {
        bulkOrders: {
          type: CrudBulkOrder,
          props: {
            openInFullscreen: false,
            parentID: 'customerID',
            parentIdFilterField: 'customerID',
            permissionCode: 'Customer'
          }
        }
      }
    ]
  };
}

const maxNumber = 999999999999999;

const layoutTabGeneral = {
  named: true,
  sections: [
    {
      width: 50,
      fields: ['name', 'snelStartKlantCode', 'firstName', 'lastName', 'userName', 'email']
    },
    {
      width: 50,
      fields: [
        'customerSpecificCategoryName',
        'billingAddressID',
        'shippingAddressID',
        'syncError',
        'publishToWebshopYN'
      ]
    }
  ]
};

const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutTabGeneral]
  };
}

export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutTabGeneral, layoutFullWidth]
  };
}

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
