import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import PickRec from '@/components/DSE/PickRec';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    productID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'common.product',
        valueField: 'id',
        labelField: 'name',
        inputField: 'name',
        maxlength: 64,
        columns: [{ field: 'name', label: 'common.name' }],
        entity: 'Product',
        usePagination: true,
        hideInput: false,
        suggest: true
      }
    },
    quantity: {
      type: MDinput,
      props: {
        type: 'number',
        minValue: 0,
        required: true,
        precision: 0
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
